import React, {useEffect, useState, useCallback, useMemo, useRef} from 'react';

import {Logo} from '../../components';
import {Link} from '../../components/common';
import {Page} from '../../types';
import {isBrowser} from '../../utils/isBrowser';
import {useStore} from '../../store';

import {
  NavDownloadCTA,
  NavDownloadCTAMob,
  BurgerMenu,
  BurgerPanel,
} from './index';

const lightTextPages: Page[] = ['home', 'teens'];

const yellowBackgroundPages: Page[] = ['schools'];

interface INavHeader {
  activePage: Page;
}

export function NavHeader({activePage}: INavHeader) {
  const logoDisplayMode = lightTextPages.includes(activePage)
    ? 'light'
    : 'dark';

  const buttonDisplayMode = yellowBackgroundPages.includes(activePage)
    ? 'bg-primaryred ml-6 justify-center hover:bg-[#EBD384]'
    : undefined;
  const textColor =
    logoDisplayMode === 'dark' ? 'text-black' : 'text-background';

  const {sideMenuOpen, onSetSideMenuOpen} = useStore();
  const [scrollPosition, setScrollPosition] = useState(
    isBrowser ? window.scrollY : 0,
  );
  const scrollPositionRef = useRef(scrollPosition);
  const [styling, setStyling] = useState('');

  const pastTop = useMemo(() => {
    return scrollPosition > 50;
  }, [scrollPosition]);

  const onHandleScroll = useCallback(() => {
    setScrollPosition(window.scrollY);
  }, []);

  useEffect(() => {
    if (isBrowser) {
      window.addEventListener('scroll', onHandleScroll);
      return () => {
        window.removeEventListener('scroll', onHandleScroll);
      };
    }
  }, []);

  useEffect(() => {
    // within top 50px show the standard nav bar
    // when scrolling down beyond 50px fade out the nav bar
    // when scrolling up beyond 50px show the white BG nav bar
    if (scrollPosition !== scrollPositionRef.current) {
      if (scrollPosition > scrollPositionRef.current) {
        if (scrollPosition > 50) {
          setStyling('animate-fadeOut');
        }
      } else {
        if (scrollPosition <= 50) {
          setStyling('animate-fadeBackground');
        } else {
          setStyling('animate-fadeIn bg-background bg-opacity-100');
        }
      }
    }
    scrollPositionRef.current = scrollPosition;
  }, [scrollPosition]);

  return (
    <div className={`fixed z-50 top-0 left-0 right-0 bg-none ${styling}`}>
      <div className="flex justify-center mx-8 lg:mx-28">
        <div className="py-3 w-full max-w-screen-xl">
          <div className="flex items-center justify-between">
            <BurgerMenu
              onClick={() => {
                onSetSideMenuOpen(true);
              }}
              mode={pastTop ? 'dark' : logoDisplayMode}
            />
            <div className="relative mx-auto lg:mx-0">
              <Link linkTo="home">
                <Logo mode={pastTop ? 'dark' : logoDisplayMode} />
              </Link>
            </div>
            <div
              className={`${
                pastTop ? 'dark' : textColor
              } items-center hidden md:flex`}>
              <Link className="px-3 hover:underline" linkTo="premium">
                luna premium
              </Link>
              <Link className="px-3 hover:underline" linkTo="about-us">
                about us
              </Link>
              <Link className="px-3 hover:underline" linkTo="teens">
                i&apos;m a teen
              </Link>
              <Link className="px-3 hover:underline" linkTo="parents">
                i&apos;m a parent
              </Link>
              {/* <Link className="px-3 hover:underline" linkTo="work-with-us">
                work with us
              </Link> */}
              <Link className="px-3 hover:underline" linkTo="schools">
                schools
              </Link>
              {activePage !== 'download' && (
                <NavDownloadCTA className={buttonDisplayMode} />
              )}
            </div>
            {activePage !== 'download' ? (
              <NavDownloadCTAMob
                color={
                  yellowBackgroundPages.includes(activePage)
                    ? 'bg-primaryred'
                    : undefined
                }
              />
            ) : (
              <div className="w-[50px] lg:hidden"></div>
            )}
          </div>
        </div>
      </div>
      <BurgerPanel
        open={sideMenuOpen}
        onClose={() => {
          onSetSideMenuOpen(false);
        }}
      />
    </div>
  );
}
