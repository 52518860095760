import React, {
  createContext,
  useState,
  useContext,
  PropsWithChildren,
} from 'react';

const defaultContext = {
  sideMenuOpen: false,
  onSetSideMenuOpen: (open: boolean) => {},
};

const StoreContext = createContext(defaultContext);

export function StoreProvider({children}: PropsWithChildren<{}>) {
  const [sideMenuOpen, onSetSideMenuOpen] = useState(false);
  return (
    <StoreContext.Provider value={{sideMenuOpen, onSetSideMenuOpen}}>
      {children}
    </StoreContext.Provider>
  );
}

export function useStore() {
  return useContext(StoreContext);
}
